header{
    height: 150vh;
    padding-top:7rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}
/*cta*/
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
/*socials*/
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8;
    position: absolute;
    left: 0;
    bottom: 7rem;
}


.header__socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*img*/
.me{

    width: 30rem;
    height: 70rem;
    position: absolute;
    left: calc(50% - 14rem);
    margin-top: 2rem;
    border-radius: 30rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    
}

.scroll__down{
    position: absolute;
    right:-2.3rem;
    bottom: 9rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

@media screen and (max-width:1030px) {
   header{
    height: 130vh;
   }

   .me{
    height: 28rem;
    width: 25rem;
    position: absolute;
left: calc(50% - 12rem);
}
}

@media screen and (max-width:600px) {
    header{
        height: 100vh;
    }
    .header__socials{
        position: absolute;
        bottom: 11rem;
    }
    
    .scroll__down{
        position: absolute;
        bottom: 14rem;
    }

    .me{
        height: 30rem;
        width: 20rem;
        position: absolute;
    left: calc(50% - 10rem);
    }
}



@media screen and (max-width:820px) {
    header{
        height: 75vh;
    }
    .header__socials{
        position: absolute;
        bottom: 9rem;
    }
    
    .scroll__down{
        position: absolute;
        bottom: 12rem;
    }

    .me{
        height: 30rem;
        width: 20rem;
        position: absolute;
    left: calc(50% - 10rem);
    }
}



@media screen and (max-width:550px) {
    header{
        height: 100vh;
    }
    .header__socials{
        position: absolute;
        bottom: 7rem;
    }
    
    .scroll__down{
        position: absolute;
        bottom: 10rem;
    }

    .me{
        height: 30rem;
        width: 20rem;
        position: absolute;
    left: calc(50% - 10rem);
    }
}

@media screen and (max-width:300px) {
    
    header{
        height: 100vh;
        margin-bottom: 3rem;
    }
    .header__socials{
        display: none;
    }
    
    .scroll__down{
        display: none;
    }

    .me{
        z-index: 2;
        width: 30rem;
    height: 70rem;
    position: absolute;
    left: calc(50% - 14rem);
    margin-top: -3rem;
    margin-bottom: 10rem;
    border-radius: 30rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
    }
}

@media screen and (max-width:400px) {
    header{
        height: 100vh;
    }
    .header__socials{
        position: absolute;
        bottom: 10rem;
    }
    
    .scroll__down{
        position: absolute;
        bottom: 13rem;
    }

    .me{
        height: 30rem;
        width: 20rem;
        position: absolute;
    left: calc(50% - 10rem);
    }
}

@media screen and (min-width:830px) and (max-width:950px) {
    header{
        height: 60vh;
    }
    .header__socials{
        position: absolute;
        bottom: 10rem;
    }
    
    .scroll__down{
        position: absolute;
        bottom: 13rem;
    }

    .me{
        height: 30rem;
        width: 20rem;
        position: absolute;
    left: calc(50% - 10rem);
    }
}










